import * as React from "react"

import { SEO } from "../components/seo"
import { PrimaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import { clearSnappy } from "../model/helpers"

const Sweeprules = () => (
  <>
    {clearSnappy()}
    <PrimaryHeader />
    <SEO isQuiz={true} title="Sweeprules" url="sweeprules" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="body-wrap">
            <div className="row">
              <div className="col-sm-12 listicle-copy">
                <div
                  style={{
                    lineHeight: "100%",
                    background: "#d5dce4",
                    border: "1px solid #000000",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <b>INTERNAL USE ONLY</b>
                  </div>
                  <div>
                    <b>US Sweepstakes &amp; Fulfillment Company</b>
                  </div>
                  <div>
                    <b>TriviaBoss Trivia Sweepstakes</b>
                  </div>
                  <div>
                    <b>Promotion runs from 1/7/20 to 2/3/20</b>
                  </div>
                </div>
                <div style={{ marginBottom: "20px", textAlign: "center" }}>
                  <div>
                    <b>
                      THE TRIVIABOSS TRIVIA SWEEPSTAKES
                      <br />
                    </b>
                  </div>
                  <div>
                    <b>OFFICIAL RULES</b>
                  </div>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <b>
                    NO PURCHASE NECESSARY TO ENTER OR WIN. MAKING A PURCHASE WILL NOT IMPROVE YOUR CHANCES OF WINNING.
                  </b>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <b>
                    ALL DISPUTES WILL BE RESOLVED SOLELY BY BINDING ARBITRATION AND ENTRANTS WAIVE THE ABILITY TO BRING
                    CLAIMS IN A CLASS ACTION FORMAT.
                  </b>
                </div>
                <div>
                  <b>ELIGIBILITY: The TriviaBoss Trivia Sweepstakes </b>(the “Sweepstakes”) is open to legal residents
                  of the 50 United States, including the District of Columbia, who are age 18 or older at the time of
                  entry. Void in Puerto Rico, Guam, the U.S. Virgin Islands, outside the U.S., and wherever else
                  prohibited by law. Employees of Gray State Ventures (the "Sponsor"), US Sweepstakes &amp; Fulfillment
                  Company (the “Sweepstakes Administrator”) and their respective subsidiaries, affiliates, advertising
                  and promotion agencies, and the immediate family members (spouses, parents, children, and siblings and
                  their spouses) of, and/or those living in the same household of each, are not eligible to enter.
                  Sweepstakes is subject to all applicable federal, state and local laws and regulations.
                </div>

                <div style={{ margin: "20px 0" }}>
                  <b>SWEEPSTAKES ENTRY PERIOD:</b> The Sweepstakes begins at 12:00:01 AM Eastern Time (“ET”) on Tuesday,
                  January 7, 2020 and ends at 11:59:59 PM ET on Monday, February 3, 2020 (the “Sweepstakes Period”).
                  Within the Sweepstakes Period, there are four (4) Weekly Entry Periods (each, a “Weekly Entry Period”)
                  and four (4) corresponding Weekly Random Drawings (each, a “Drawing”) as defined in the “Weekly Entry
                  Periods &amp; Drawings Schedule” below. Entries must be received during a Weekly Entry Period to be
                  eligible for that Weekly Entry Period’s drawing and prize.{" "}
                  <u>
                    Non-winning entries for a Weekly Entry Period will not roll over to subsequent Weekly Entry Periods.
                  </u>{" "}
                  Sponsor’s computer is the official timekeeping device for this Sweepstakes.
                </div>
                <table className="sweepsrules-table">
                  <tr>
                    <td colSpan={6}>
                      <b>WEEKLY ENTRY PERIOD &amp; DRAWINGS SCHEDULE</b>
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>
                      <b>Entry Period #</b>
                    </td>
                    <td colSpan={2}>
                      <p>
                        <b>WEEKLY ENTRY PERIODS</b>
                      </p>
                      <p>
                        <b>All eligible entries must be received between:</b>
                      </p>
                    </td>
                    <td rowSpan={2}>
                      <b>Mail-In Received By Date</b>
                    </td>
                    <td rowSpan={2}>
                      <p>
                        <b>Drawing Date: </b>
                      </p>
                      <p>On or about:</p>
                    </td>
                    <td rowSpan={2}>
                      <b># of Prizes per Weekly Entry Period</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <b>Weekly Entry Period Start:</b>
                      </p>
                      <p>
                        <b>12:00:01 AM ET on:</b>
                      </p>
                    </td>
                    <td>
                      <p>
                        <b>Weekly Entry Period End:</b>
                      </p>
                      <p>
                        <b>11:59:59 PM ET on:</b>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>1/7/20</td>
                    <td>1/13/20</td>
                    <td>1/21/20</td>
                    <td>1/23/20</td>
                    <td>1 Grand Prize</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>1/14/20</td>
                    <td>1/20/20</td>
                    <td>1/27/20</td>
                    <td>1/30/20</td>
                    <td>1 Grand Prize</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>1/21/20</td>
                    <td>1/27/20</td>
                    <td>2/3/20</td>
                    <td></td>
                    <td>1 Grand Prize</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>1/28/20</td>
                    <td>2/3/20</td>
                    <td>2/10/20</td>
                    <td>2/13/20</td>
                    <td>1 Grand Prize</td>
                  </tr>
                </table>
                <div style={{ margin: "20px 0" }}>
                  <b>HOW TO ENTER:</b> There are two(2) ways to enter.{" "}
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>Method #1: Online Trivia Entry.</b> During the Sweepstakes Period, visit{" "}
                  <a style={{ textDecoration: "none", color: "#0066cc" }} href="https://triviaboss.com/">
                    https://triviaboss.com/
                  </a>
                  , complete a trivia quiz, and follow all entry instructions to complete the entry form and submit to
                  receive one (1) entry into the Sweepstakes (the “Online Trivia Entry”).{" "}
                  <b>
                    Entries must be received in accordance with the Weekly Entry Period &amp; Drawings Schedule above to
                    be eligible for the Drawing
                  </b>
                  . Entrants are subject to all notices posted online including but not limited to the Sponsor’s Privacy
                  Policy, which can be found at{" "}
                  <a style={{ textDecoration: "none", color: "#0066cc" }} href="https://triviaboss.com/privacy/">
                    https://triviaboss.com/privacy/
                  </a>
                  .
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>Method #2: Alternate Method of Entry (“AMOE”) / Mail-in Entry. </b> To enter without playing
                  trivia, mail a postcard (the “Mail-In
                  <br />
                  Entry”), including your name, complete address, email address, phone number and the Entry Period you
                  are submitting your entry for as follows:
                  <ul>
                    <li>
                      For Entry Period 1, mail to: Trivia Boss Weekly Trivia Sweepstakes, Entry Period 1, 625 Panorama
                      Trail Suite 2100, Rochester, NY 14625-2437.
                    </li>
                    <li>
                      For Entry Period 2, mail to: Trivia Boss Weekly Trivia Sweepstakes, Entry Period 2, 625 Panorama
                      Trail Suite 2100, Rochester, NY 14625-2437.
                    </li>
                    <li>
                      For Entry Period 3, mail to: Trivia Boss Weekly Trivia Sweepstakes, Entry Period 3, 625 Panorama
                      Trail Suite 2100, Rochester, NY 14625-2437.
                    </li>
                    <li>
                      For Entry Period 4, mail to: Trivia Boss Weekly Trivia Sweepstakes, Entry Period 4, 625 Panorama
                      Trail Suite 2100, Rochester, NY 14625-2437.
                    </li>
                  </ul>
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>
                    Each Mail-In Entry must be postmarked between the Entry Period Start Date and Mail-In Entry Postmark
                    Date and received by the Mail-In Entry
                    <br />
                    Received-By Date as outlined in the Weekly Entry Period &amp; Drawings Schedule.{" "}
                  </b>{" "}
                  Photocopied, illegible, or mechanically reproduced entries are not eligible. All
                  <br />
                  entries become the property of the Sponsor and will not be returned or acknowledged. Sponsor is not
                  responsible for lost, late, damaged, misdirected or postage-due mailed entries.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>Limit: Three (3) entries per person per Entry Period regardless of method of entry</b>. All methods
                  of entry have an equal chance of winning. All entries become the property of the Sponsor and will not
                  be acknowledged or returned.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>PRIZES/APPROXIMATE RETAIL VALUE (“ARV”)/ODDS</b>
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>Grand Prize: </b>There are four (4) Grand Prizes available to be won, one (1) per Entry Period.
                  Grand Prize is one (1) $1,000 check that will be awarded in the name of the confirmed winner.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>The ARV of Grand Prize: $1,000 each. The total ARV of all Grand Prizes is: $4,000.</b>
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>The total ARV of all prizes available to be won: $4,000.</b>
                </div>
                <p>
                  There is a limit of one (1) prize per person. Odds of winning will depend upon the total number of
                  eligible entries received.
                </p>
                <div style={{ margin: "20px 0" }}>
                  <b>RANDOM DRAWING:</b> The winners will be selected in series of random drawings as outlined in the
                  Weekly Entry Period &amp; Drawings Schedule from
                  <br />
                  among all eligible entries received during that Entry Period by the Sweepstakes Administrator, an
                  independent representative of the Sponsor whose decisions are final.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>WINNER NOTIFICATION &amp; VERIFICATION: </b>Potential Grand Prize winners will be notified via
                  email and/or phone, and will be required to sign and return, within five (5) days of notification, an
                  Affidavit of Eligibility, a Liability Waiver, an IRS W-9 Form and where allowable, a Publicity Release
                  (collectively, "the Releases"). These Releases will require the Grand Prize winner to furnish his/her
                  Social Security Number for the sole purpose of tax reporting, as required by law. Noncompliance will
                  result in disqualification and an alternate winner may be selected.{" "}
                  <u>prize package will be awarded within approximately 45 days after the winner is verified.</u>
                </div>
                <div style={{ margin: "20px 0" }}>
                  If a winner is otherwise eligible under these Official Rules, but is nevertheless deemed a minor in
                  his or her state of primary residence,
                  <br />
                  the prize will be awarded in the name of winner’s parent or legal guardian who will be required to
                  execute and return the Releases on minor’s behalf, as applicable.
                </div>
                <div style={{ margin: "20px 0" }}>
                  If a prize notification or prize is returned as undeliverable, or if winner is found to be ineligible
                  or not in compliance with these
                  <br />
                  rules that winner will be disqualified, and the prize may be awarded to an alternate winner in a
                  separate random drawing. Only two (2) alternate winners may be determined through this process, after
                  which the prize may remain un-awarded.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>PRIZE CONDITIONS: </b>Prize is not assignable nor transferable. No substitution is permitted except
                  if prize is unavailable, in which case a prize of equal or greater value will be awarded. No cash in
                  lieu of prizes and no exchange or substitution of prizes, except at the sole discretion of the
                  Sponsor. Any other incidental expenses on prize not specified herein are the winner’s sole
                  responsibility.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <u>
                    Winner is responsible for all federal, state, local and income taxes associated with winning prize.
                    Grand Prize winner will be required to
                    <br />
                    furnish his/her Social Security Number for the sole purpose of preparation of tax forms as required
                    by law.
                  </u>{" "}
                  Except where prohibited by law, entry and acceptance of prize
                  <br />
                  constitute permission to use winner’s name, prize won, hometown, likeness, video tape, photographs,
                  and statements for purposes of advertising, promotion and publicity (including online posting) in any
                  and all media now or hereafter known throughout the world in perpetuity, without additional
                  compensation, notification or permission.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>GENERAL:</b> Participating entrants agree to these Official Rules and the decisions of the Sponsor
                  and the Sweepstakes Administrator, and
                  <br />
                  release the Sponsor, the Sweepstakes Administrator, and their affiliated companies, and all other
                  businesses involved in this Sweepstakes, as well as the employees, officers, directors and agents of
                  each (the “Released Parties”), from all claims and liability relating to their participation in the
                  promotion, and the acceptance and use/misuse of the prize offered. Winner assumes all liability for
                  any injury or damage caused or claimed to be caused, by participation in this Sweepstakes or
                  use/misuse or redemption of the prize. Sponsor is not responsible for any typographical or other error
                  in the printing of the offer, administration of the Sweepstakes or in the announcement of the prize.{" "}
                </div>
                <div style={{ margin: "20px 0" }}>
                  In the event of a dispute over the identity of an entrant, entry will be deemed submitted by the
                  "Authorized Account Holder" of the
                  <br />
                  e-mail address submitted at time of entry. Authorized Account Holder means the natural person who is
                  assigned to an e-mail address by an Internet access provider, online service provider, or other
                  organization that is responsible for assigning e-mail addresses for the domain associated with the
                  submitted e-mail address. Sponsor may ask any entrant or potential winner to provide Sponsor with
                  proof, to Sponsor’s satisfaction, that such party is the authorized account holder of the email
                  address associated with the
                  <br />
                  entry. Entry materials/data that have been tampered with or altered, or mass entries or entries
                  generated by a script, macro or use of automated devices are void. The Released Parties are not
                  responsible for: (i) lost, late, misdirected, damaged or illegible entries; or (ii) error, omission,
                  interruption, deletion, defect, delay in operations or transmission, theft or destruction or
                  unauthorized access to or alterations of entry materials, or for technical, network, telephone
                  equipment, electronic, computer, hardware or software malfunctions of any kind, or inaccurate
                  transmission of or failure to receive entry information by Sponsor on account of
                  <br />
                  technical problems or traffic congestion on the Internet or at any web site or any combination
                  thereof; or (iii) any injury or damage to entrant’s or any other person’s computer related to or
                  resulting from participating in the Sweepstakes. By participating in the Sweepstakes, you (i) agree to
                  be bound by these official rules, including all eligibility requirements, and (ii) agree to be bound
                  by the decisions of Sponsor and the Sweepstakes Administrator, which are final and binding in all
                  matters relating to the Sweepstakes. Failure to comply with these official rules may result in
                  disqualification from the Sweepstakes. Sponsor reserves the right to: (i) permanently disqualify from
                  any Sweepstakes it sponsors any person it believes has intentionally violated these official rules;
                  and (ii) withdraw a method of entry if it becomes technically corrupted (including if a computer virus
                  or system malfunction inalterably impairs its ability to conduct the Sweepstakes). If for any reason
                  this Sweepstakes is not capable of running as planned due to an infection by a computer virus, bugs,
                  tampering, unauthorized intervention, fraud, technical failures, or any other causes beyond the
                  control of the Sponsor which corrupt or affect the administration, security, fairness, integrity, or
                  proper conduct of this Sweepstakes, the Sponsor reserves the right at its sole
                  <br />
                  discretion, to disqualify any individual who tampers with the entry process, and to cancel, terminate,
                  modify or suspend the Sweepstakes,at which time, Sponsor will conduct a random drawing from among all
                  eligible entries received at the time of the Sweepstakes termination.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>LEGAL WARNING:</b> ANY ATTEMPT BY AN INDIVIDUAL, WHETHER OR NOT AN ENTRANT, TO DAMAGE, DESTROY,
                  TAMPER OR VANDALIZE THIS WEB SITE OR INTERFERE WITH THE OPERATION OF THE SWEEPSTAKES, IS A VIOLATION
                  OF CRIMINAL AND CIVIL LAWS AND SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES AND DILIGENTLY PURSUE ALL
                  REMEDIES
                  <br />
                  AGAINST ANY SUCH INDIVIDUAL TO THE FULLEST EXTENT PERMITTED BY LAW.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>GOVERNING LAW AND LIMITATION OF LIABILITY:</b>All issues and questions concerning the construction,
                  validity, interpretation and enforceability of these Official Rules or the rights and obligations of
                  entrants, Sponsor or the Released Parties in connection with the Sweepstakes will be governed by and
                  construed in accordance with the internal laws of the State of Delaware, without giving effect to any
                  choice of law or conflict of law rules or provisions that would cause the application of any other
                  laws.
                </div>
                <div style={{ margin: "20px 0" }}>
                  BY ENTERING THE SWEEPSTAKES, ENTRANT AGREES THAT TO THE EXTENT PERMITTED BY APPLICABLE LAW: (A) ANY
                  AND ALL DISPUTES, CLAIMS AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE SWEEPSTAKES, OR ANY
                  PRIZE AWARDED, WILL BE RESOLVED INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION; (B) ANY AND
                  ALL CLAIMS, JUDGMENTS AND AWARDS WILL BE LIMITED TO ACTUAL THIRD-PARTY, OUT-OF-POCKET COSTS INCURRED
                  (IF ANY) NOT TO EXCEED TEN DOLLARS ($10.00), BUT IN NO EVENT WILL ATTORNEYS’
                  <br />
                  FEES BE AWARDED OR RECOVERABLE; (C) UNDER NO CIRCUMSTANCES WILL ANY ENTRANT BE PERMITTED TO OBTAIN ANY
                  AWARD FOR, AND ENTRANT HEREBY KNOWINGLY AND EXPRESSLY WAIVES ALL RIGHTS TO SEEK, PUNITIVE, INCIDENTAL,
                  CONSEQUENTIAL OR SPECIAL DAMAGES, LOST PROFITS AND/OR ANY OTHER DAMAGES, OTHER THAN ACTUAL OUT OF
                  POCKET EXPENSES NOT TO EXCEED TEN DOLLARS ($10.00), AND/OR ANY RIGHTS TO HAVE DAMAGES MULTIPLIED OR
                  OTHERWISE INCREASED; AND (D) ENTRANTS’ REMEDIES ARE LIMITED TO A CLAIM FOR MONEY DAMAGES (IF ANY) AND
                  ENTRANT IRREVOCABLY WAIVES ANY RIGHT TO SEEK INJUNCTIVE OR EQUITABLE RELIEF. SOME JURISDICTIONS DO NOT
                  ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY, SO THE ABOVE MAY NOT APPLY TO YOU.{" "}
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>DISPUTES:</b>The parties hereto each agree to finally settle all disputes only through arbitration;
                  provided, however, the Released Parties shall be entitled to seek injunctive or equitable relief in
                  the state and federal courts in New Castle County, Delaware and any other court with jurisdiction over
                  the parties. In arbitration, there is no judge or jury and review is limited. The arbitrator’s
                  decision and award is final and binding, with limited exceptions, and judgment on the award may be
                  entered in any court with jurisdiction. The parties agree that, except as set forth above, any claim,
                  suit, action or proceeding arising out of or relating to this Sweepstakes shall be resolved solely by
                  binding arbitration before a sole arbitrator under the streamlined Arbitration Rules Procedures of
                  JAMS Inc. (“JAMS”) or any successor to JAMS. In the event JAMS is unwilling or unable to set a hearing
                  date within fourteen (14) days of the filing of a “Demand for Arbitration”, then either party can
                  elect to have the arbitration administered by the American Arbitration Association (“AAA”) or any
                  other mutually agreeable arbitration administration service. If an in-person hearing is required, then
                  it will take place in New Castle County, Delaware. The federal or state law that applies to these
                  Official Rules will also apply during the arbitration. Disputes will be arbitrated only on an
                  individual basis and will not be consolidated with any other proceedings that involve any claims or
                  controversy of another party, including any class actions; provided, however, if for any reason any
                  court or arbitrator holds that this restriction is unconscionable or unenforceable, then the agreement
                  to arbitrate doesn’t apply and the dispute must be brought in a court of competent jurisdiction in New
                  Castle County, Delaware. Sponsor agrees to pay the administrative and arbitrator’s
                  <br />
                  fees in order to conduct the arbitration (but specifically excluding any travel or other costs of
                  entrant to attend the arbitration hearing). Either party may, notwithstanding this provision, bring
                  qualifying claims in small claims court.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>PRIVACY:</b> Personal information collected from entrants are subject to the Sponsor’s Privacy
                  Policy, which can be found at{" "}
                  <a style={{ textDecoration: "none", color: "#0066cc" }} href="https://triviaboss.com/privacy/">
                    https://triviaboss.com/privacy/
                  </a>
                  .
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b> OFFICIAL RULES REQUEST: </b> To request a copy of the Official Rules, see{" "}
                  <a style={{ textDecoration: "none", color: "#0066cc" }} href="https://triviaboss.com/sweepsrules">
                    https://triviaboss.com/sweepsrules
                  </a>{" "}
                  or send a self-addressed, stamped envelope by February 13, 2020, to:{" "}
                  <b>The TriviaBoss Trivia Sweepstakes</b>
                  <u>Official Rules Request</u>, PO Box 25466, Rochester, NY 14625-0466.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>WINNER CONFIRMATION REQUEST: </b>For a written confirmation of the winner (available after February
                  13, 2020), send a stamped, self-addressed envelope (no later than March 12, 2020), to:{" "}
                  <b>The TriviaBoss Trivia Sweepstakes</b> <u>Winner Confirmation Request</u>, PO Box 25466, Rochester,
                  NY 14625-0466.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>SPONSOR: </b>Grey State Ventures, 108 West 13<sup>th</sup> Street, Wilmington, DE 19801.
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>SWEEPSTAKES ADMINISTRATOR: </b>US Sweepstakes &amp; Fulfillment Company, 625 Panorama Trail, Suite
                  2100, Rochester, NY 14625. 1-800-620-6044
                </div>
                <div style={{ margin: "20px 0" }}>
                  <b>
                    Void outside the US, in Puerto Rico, Guam, the U.S. Virgin Islands and wherever else prohibited by
                    law.
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

export default Sweeprules
